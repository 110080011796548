<template>
  <div class="big-box">
    <el-form label-position="left" :inline="true" class="demo-form-inline">
      <el-form-item label="手机号">
        <el-input v-model="userPhone" placeholder="请输入用户手机号" oninput="if(value.length>11)value=value.slice(0,11)">
        </el-input>
      </el-form-item>
      <!-- <el-form-item label="商品类型：">
        <el-select v-model="goodsType" placeholder="全部">
          <el-option
            label="配件"
            value="1"
          ></el-option>
          <el-option
            label="周边"
            value="2"
          ></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <el-button type="primary" @click="search('clear')">查询</el-button>
        <el-button @click="clearModuleCode">重置</el-button>
      </el-form-item>
    </el-form>
    <div>
      <template>
        <el-table :data="tableData" tooltip-effect="dark" style="width: 90%; margin-left: 40px">

          <el-table-column prop="id" align="center" label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="goods_name" align="center" label="订单商品" min-width="120">
          </el-table-column>

          <el-table-column prop="order_no" align="center" label="订单编号" min-width="210">
          </el-table-column>
          <!-- <el-table-column
            prop="goods_type_cn"
            align="center"
            label="商品类型"
            min-width="90"
          >
            <template slot-scope="scope">
              <span>{{scope.row.location}} {{scope.row.address}}</span>
            </template>
          </el-table-column> -->
          <el-table-column prop="user_name" align="center" min-width="150" label="用户昵称">
          </el-table-column>
          <el-table-column prop="address" align="center" min-width="120" label="用户地址">
            <template slot-scope="scope">
              <span>{{ scope.row.location }} {{ scope.row.address }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="phone" align="center" min-width="120" label="手机号">
          </el-table-column>
          <el-table-column prop="status_cn" align="center" min-width="100" label="状态">
          </el-table-column>
          <el-table-column prop="created_at" align="center" min-width="150" label="下单时间">
          </el-table-column>
          <el-table-column prop="op_at" align="center" min-width="120" label="状态变更时间">
          </el-table-column>
          <el-table-column prop="date" align="center" min-width="150" label="操作" v-if="$has('edit')">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="detailGoodsPop(scope.row, scope.row.status)">
                {{ scope.row.status == 0 ? '发货' : '详情' }}</el-button>
              <!-- <el-button v-if="scope.row.status == 0" type="primary" size="small"
                @click="detailGoodsPop(scope.row, 0)"
                >发货</el-button>
              <el-button v-if="scope.row.status == 1" type="primary" size="small"
                @click="detailGoodsPop(scope.row, 1)"
                >详情</el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="margin-left: 50px;margin-top: 10px" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" :current-page.sync="page" :page-size="10"
          layout="total, prev, pager, next" :total="total">
        </el-pagination>
      </template>
    </div>
    <el-dialog :visible.sync="dialogPvVisible" width="1000px" :title="statusType == 1 ? '详情' : '发货'">
      <el-form label-width="150px" :inline="true" :disabled="isFormDisabled">
        <el-form-item label="商品名称:">
          <div class="text-content">{{ orderDetail.goods_name }}</div>
        </el-form-item>
        <el-form-item label="订单状态:">
          <div class="text-content">{{ orderDetail.status_cn }}</div>
        </el-form-item>
        <el-form-item label="收货人:">
          <div class="text-content">{{ orderDetail.user_name }}</div>
        </el-form-item>
        <el-form-item label="手机号:">
          <div class="text-content">{{ orderDetail.phone }}</div>
        </el-form-item>
        <el-form-item label="地址信息:">
          <div style="width: 700px" class="text-content">{{ orderDetail.location }}{{ orderDetail.address }}</div>
        </el-form-item>

        <el-form-item label="下单时间:">
          <div class="text-content">{{ orderDetail.created_at }}</div>
        </el-form-item>
        <el-form-item required label="发货方式:">
          <el-select v-model="goodsType" style="width: 600px; margin-left: 20px" placeholder="--请选择--">
            <el-option label="需发货" :value="1"></el-option>
            <el-option label="无需发货" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item required label="快递公司:" v-if="goodsType == 1">
          <el-input style="width: 600px; margin-left: 20px" placeholder="请输入快递公司" v-model="express_company"></el-input>
        </el-form-item>
        <el-form-item required label="快递单号:" v-if="goodsType == 1">
          <el-input style="width: 600px; margin-left: 20px" placeholder="请输入快递单号" v-model="express_no"></el-input>
        </el-form-item>
        <el-form-item label="备注:" v-if="goodsType == 2">
          <el-input style="width: 600px; margin-left: 20px" placeholder="请输入备注" v-model="remark"></el-input>
        </el-form-item>
        <br>
        <el-form-item label="发货图:" v-if="goodsType == 2">
          <div style="margin-left: 20px">
            <el-button type="primary" style="vertical-align: middle;" size="medium"
              v-if="delivery_img && delivery_img.length < 3" @click="newAdd">添加图片</el-button>
            <div v-for="(item, index) in delivery_img" class="img-box" :key="index">
              <img @click="checkImg(item.all_url)" :src="item.all_url" class="img-style" alt="">
              <!-- <i @click="deleteimg(index)" class="el-icon-delete"></i> -->
              <el-button class="el-icon-delete" circle @click="deleteimg(index)"></el-button>
            </div>

          </div>

          <!-- <el-upload
            action="#"
            list-type="picture-card"
            :auto-upload="false">
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{file}">
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url" alt=""
                >
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
          </el-upload> -->
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPvVisible = false">取消</el-button>
        <el-button type="primary" v-if="statusType !== 1" @click="sendGoods">确认</el-button>
        <el-button type="primary" v-if="statusType == 1 && isFormDisabled" @click="toEditStatus">修改</el-button>
        <el-button type="primary" v-if="statusType == 1 && !isFormDisabled" @click="sendGoods">确认</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <el-dialog :visible.sync="dialogVisibless" width="600px" title="新增">
      <el-form label-width="150px" :model="createModule">
        <el-form-item required label="图片名称:">
          <el-input placeholder="请输入图片名称" v-model="createModule.name"></el-input>
        </el-form-item>
        <el-form-item required label="选择文件:">
          <el-upload class="upload-demo" ref="upload" action="/api/admin/heat_curve/del" :on-preview="handlePreview"
            :on-remove="handleRemove" :on-change="handleChange" :limit="1" :file-list="fileList" :auto-upload="false">
            <el-button size="small" type="primary">选择文件</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibless = false">取消</el-button>
        <el-button type="primary" @click="submitadd">确认上传</el-button>
      </span>
    </el-dialog>

    <CheckPhoto ref="CheckPhoto" :imgUrl="imgUrl"></CheckPhoto>
  </div>
</template>
<script>
import { getorderlist, order_send, mediaUpload, getimgUrl } from "@/api/data.js";
import CheckPhoto from "@/components/CheckPhoto.vue";
export default {
  components: { CheckPhoto },
  data() {
    return {
      page: 1,
      orderDetail: {},
      statusType: '',
      imgUrl: '',
      total: 0,
      goodsType: '',
      dialogImageUrl: '',
      dialogVisible: false,
      // 表单是否禁止输入
      isFormDisabled: true,
      disabled: false,
      express_company: '',  // 快递公司
      express_no: '',  // 快递号
      userPhone: '',
      order_id: '',
      remark: '',
      file: '',
      fileList: [],
      delivery_img: [],

      dialogPvVisible: false,
      multipleSelection: [],
      createModule: {},
      dialogVisibless: false,
      tableData: [],
      roles: ['admin', 'user'],
    };
  },
  created() {
  },
  watch: {
  },
  mounted() {
    this.search('clear')
  },
  methods: {
    handleRemove(file) {
      console.log(file);
    },
    newAdd() {
      this.dialogVisibless = true;
      this.createModule = {};
      this.fileList = []
      this.file = ''
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    deleteimg(index) {
      this.delivery_img.splice(index, 1)
    },
    detailGoodsPop(item, type) {  // type0  发货，1详情
      this.dialogPvVisible = true
      this.statusType = type
      if (type === 1) {
        this.isFormDisabled = true
      } else {
        this.isFormDisabled = false
      }
      this.orderDetail = item
      this.goodsType = item.delivery_type == 0 ? '' : item.delivery_type
      this.express_company = item.express_company
      this.express_no = item.express_no
      this.remark = item.remark
      this.delivery_img = item.delivery_img ? JSON.parse(JSON.stringify(item.delivery_img)) : []
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val
      this.search()
    },
    submitadd() {
      if (!this.createModule.name) {
        this.$message.error('请输入图片名称');
        return
      }
      // this.dialogPvVisible = false;
      // // this.$refs.upload.submit();
      const param = new FormData(); //提交formData
      param.append("file_name", this.file);
      param.append("media_type", 1); // 资源类型1=图片，2=视频
      param.append("file_nickname", this.createModule.name);
      param.append("platform", 1);

      mediaUpload(param).then(({ data: res }) => {
        if (res.code === 200) {
          this.$message.success("上传成功");
          this.fileList = [];
          this.dialogVisibless = false
          this.search('clear');
          this.createModule = {};
          this.delivery_img.push(res.data)
        } else {
          this.$message.error(res.message);
        }
      });
    },
    search(type) {
      if (type == 'clear') {
        this.page = 1
      }
      getorderlist(`?page=${this.page}&limit=10&phone=${this.userPhone}`).then(({ data: res }) => {
        if (res.code === 200) {
          this.tableData = res.data.data || []
          this.total = res.data.total || 0
        } else {
          this.$message.error(res.message);
        }
      });
    },
    clearModuleCode() {
      this.userPhone = ''
      this.page = 1
      this.search('clear')
    },
    handleChange(file) {
      this.file = file.raw;
    },
    handleRemove(file, fileList) {
      console.log('handleRemove', file, fileList);
    },
    handlePreview(file) {
      console.log('handlePreview', file);
    },
    checkImg(item) {
      this.imgUrl = item
      this.$refs.CheckPhoto.open();
    },
    toEditStatus() {
      this.isFormDisabled = false
    },
    sendGoods() {
      // if (!this.express_company || !this.express_no) {
      //   this.$message.error('请输入快递公司及单号');
      //   return 
      // }
      let obj = {
        order_id: this.orderDetail.order_id,
        delivery_type: this.goodsType
      }
      if (this.goodsType == 1) {
        if (!this.express_company) {
          this.$message.error('请输入快递公司');
          return
        }
        if (!this.express_no) {
          this.$message.error('请输入快递单号');
          return
        }
        obj.express_company = this.express_company  // 快递公司
        obj.express_no = this.express_no  // 快递
      } else if (this.goodsType == 2) { // 虚拟发货
        obj.remark = this.remark
        obj.delivery_img = this.delivery_img
      }
      order_send(obj).then(({ data: res }) => {
        if (res.code === 200) {
          this.$message.success("发货成功");
          this.search('clear')
          this.dialogPvVisible = false
        } else {
          this.$message.error(res.message);
        }
      });
    },
    delData(item) {
      this.$confirm("一旦删除数据，将不可恢复！", "警告", {
        distinguishCancelAndClose: true,
        confirmButtonText: "取消",
        cancelButtonText: "确认",
      })
        .then(() => { })
        .catch((action) => {
          if (action === "cancel") {
            // r0del({
            //   heat_r0_id: item.id,
            // }).then(({ data: res }) => {
            //   if (res.code === 200) {
            //     this.$message.success("删除成功");
            //   } else {
            //     this.$message.error(res.message);
            //   }
            // });
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.text-content {
  display: inline-block;
  width: 260px;
  padding-left: 20px;
}

.demo-form-inline {
  margin-left: 50px;
}

.head-box {
  height: 40px;
  margin: 20px 0 10px 40px;
}

.add-image {
  text-align: center;
  color: #3ea8d6;
}

.demo-form-inline {
  margin-top: 22px;
  // padding-left: 50px;
}

.input-box {
  width: 120px;
  margin-right: 10px;
}

::v-deep .el-input {
  width: 260px;
}

.img-box {
  display: inline-block;
  width: 100px;
  height: 100px;
  margin-left: 20px;
  vertical-align: middle;
  // border: 1px solid #ccc;
  position: relative;

  .img-style {
    width: 100px;
    height: 100px;
  }

  .el-icon-delete {
    position: absolute;
    top: -10px;
    right: -10px;
    font-size: 25px;
    border-radius: 50%;
    border: 1px #bbb solid;
    background: #fff;
    padding: 0;
  }
}
</style>
